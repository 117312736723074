/* make the customizations */
// $theme-colors: (
//     "info": tomato,
//     "danger": teal
// );

$accordion-color: #f6f9f7;


//cant set the text color in accordian because it use a color-contrast function
//based on the bacground color
//instead use regular css to control it
//$bs-accordion-color: $accordion-color;
//--#{$prefix}accordion-color: #{color-contrast($accordion-bg)};

.ad-stackitm .accordion div.accordion-item {
    color: $accordion-color;
}

.bg-adappbar {
    --bs-bg-opacity: 1;
    // background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
    // background-color: #4f5e80 !important;
    background-color: #354059 !important;
    // background-color: #203a77 !important;
    border-radius: 0.3em 0.3em 0 0;
}



$accordion-bg: #3d5d9d;

$accordion-button-active-bg: #466dbb;

$accordion-border-color: $accordion-bg;


$accordion-button-active-color: #f6f9f7;
$accordion-button-focus-border-color: $accordion-button-active-color;

$accordion-button-focus-box-shadow: $accordion-bg;





$nav-link-color: #557dce;


// does this work?
// $nav-tabs-link-bg: #234893;

//NO! but this kinda does
// .nav-item button.nav-link {
//     background-color: #3d5d9d;
// }

.ad-nftisbase .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #067b2d;
    border-color: #32bf5d;
}

.ad-nftisbase .nav-tabs .nav-link {
    color: #bed4c5;
    background-color: #067b2d;
    border-color: #125d29;
    border-bottom-color:  #32bf5d;
}

.ad-nftisbase .nav-tabs .nav-link:hover {
    color: #ffffff;
    background-color: #067b2d;
    border-color: #32bf5d;
}



$nav-link-hover-color: #557dce;


$nav-tabs-border-color: #557dce;

$nav-tabs-link-hover-border-color: #557dce;

$nav-tabs-link-active-color: #557dce;

$nav-tabs-link-active-bg: #234893;

$nav-tabs-link-active-border-color: #557dce;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
