.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  /* ad: change to allow easier view of whole app */
  font-size: calc(10px + 1vmin);
  padding: 0.3em;
  /* font-size: calc(10px + 2vmin); */
  color: white;

  /* max-height: 100vh; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ad: to make the pinch pull background black to match */
html { 
  background-color: #282c34;
}

.ad-apphomeimg {
  width: 100%;
  height: 30vh;
}

/* .ad-homeimg {
  width: 100%;
  height: 90%;
} */
/* .ad-homeimg img {
  width: 100%;
  height: 90%;
} */

.ad-homeimg img {
  width: 135px;
  height: 70px;
}
/* .ad-homeimg {
  width: 135px;
  height: 70px;
} */


.ad-helpimg {
  display: block;
  width: 25px;
  height: 100%;
}

.ad-helpbox-alert {
  background-color: #6281b0 !important;
  color: #ffffff !important;
}

/* this cause all sorts of unexpectedness downstream. rather set this on an item by item basis
.ad-App {
  text-align: center;
} */

.ad-buticon {
  height: 1.2em;
  width: 1.2em;
  vertical-align: middle;
}

.ad-accountexpando {
  font-size: 1.2rem;
  border-radius: 0.375rem;
  border-color: #737373;
  border-width: 1px;
  border-style: solid;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  /* padding-left: 0.3rem; */
}
.ad-accountexpando:hover {
  background-color: #6c757d;
  color: #ffffff;
  cursor: pointer;
}
.ad-accountexpando-icon {
  /* display: block;
  align-self: stretch; */
  /* height: 100%; */
  height: 30px !important;
  width: 30px !important;
}


.ad-buy-base {
  background-color: #bdffd3 !important;
  border-color: #00bb3e !important;
  color: #152a1c !important;
}

.ad-buy-regular {
  background-color: #a6c4ff !important;
  border-color: #2771ff !important;
  color: #152a1c !important;
}

.ad-AppWrapper {
  background-color: #282c34;
  
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; */

  /* justify-content: center; */

  /* ad: change to allow easier view of whole app */
  font-size: calc(10px + 1vmin);
  /* padding: 0.3em; */
  
  /* font-size: calc(10px + 2vmin); */
  color: white;

  /* ad: for app to fill whole screen vertically */
  height: 100vh;

  /* max-height: 100vh; */
}

.ad-AppFooter {
  /* background-color: #282c34; */
  /* ad: change to allow easier view of whole app */
  font-size: calc(8px + 1vmin);
  color: #ffffffa8;
}

.ad-topbar {
  background-color: #263352;
  border-radius: 0.3em 0.3em 0 0;
}


/* .ad-navbar-text {
  color: white !important;
} */

.ad-align-center {
  align-items: center;
}

.ad-progressbar {
    /* margin-top: 0.2rem; */
}

.ad-header-grey {
  background-color: #737373;
  border-radius: 0.3em;
}
.ad-header-grey-top {
  background-color: #737373;
  border-radius: 0.3em 0.3em 0 0;
}
.ad-header-grey-bot {
  background-color: #676666;
  border-radius: 0 0 0.3em 0.3em;
  border-color: #737373;
  border-width: 1px;
  border-style: solid;
}

.ad-attack-section {
  background-color: #234893;
  border-radius: 0.3em;
  border-color: #737373;
  border-width: 1px;
  border-style: solid;
  
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
}
.ad-nftisbase .ad-attack-section {
  background-color: #097b2d;
  border-color: #125d29;
}

.ad-attacklist-base {
  background-color: #1a823d;
  border-radius: 0.3em;
  border-color: #2f623f;
  border-width: 1px;
  border-style: solid;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* without bootstrap badges */
/* .ad-attack-ismin {
  background-color: #e45a5a;
  border-radius: 0.3em;
}
.ad-attack-isplus {
  background-color: #06b03e;
  border-radius: 0.3em;
} */

/* this overrides the bootstrap colors */
.bg-danger.ad-attack-ismin {
  background-color: #e45a5a !important;
}
.bg-success.ad-attack-isplus {
  background-color: #06b03e !important;
}

/* .ad-header-grey {
  background-color: #737373;
  border-radius: 0.3em;
} */
/* .ad-hmarg {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
} */


.cstack.ad-baselist{
  /* background-color: #4c9d68; */
}

.cstack {
  padding-top: 1rem;
  padding-bottom: 1rem;
  
}

/* .cstack.ad-stacklist {
  background-color: #4d70b3;
}
*/

.cstack.ad-stacklist .ad-nftisbase {
  background-color: #12943d;
  border-color: #06b03e;
}

.cstack.ad-stacklist .ad-ishint {
  background-color: #949699;
}


/* .cstack.ad-nftlist {
  background-color: #3f65b2;
}
*/

.cstack.ad-nftlist .ad-nftisbase {
  background-color: #12943d;
  border-color: #06b03e;
} 

.ad-tab-comp {
  background-color: #3d5d9d;
}

/* 
.ad-stacktopitm {
  border-width: medium;
  border-style: solid;
  border-color: #a09557;
} */



.ad-stackitm {
  background-color: #3d5d9d;
  /* margin:0.2rem;
  padding:0.8rem; */

  border-width: 1px;
  border-style: solid;
  border-color: #1361fd;
  border-radius: 0.3rem;
}

.ad-stackitm.ad-ismine {
  border-left: solid;
  border-left-color: #dea624;
}

.ad-baselist .ad-stackitm {
  background-color: #12943d;
}


.ad-baseitm {
  background-color: #12943d;

  border-style: solid;
  border-color: #2bca0d;
  border-radius: 0.3rem;
}

.ad-rlpmbar {
  /* display: flex;
  flex-direction: row;
  min-width: 5%;
  min-height: 120px; */
  border-style: solid;
  border-color: #737373;
  background-color: #3564c4;
  border-radius: 0.3rem;
}

/* .ad-rlpmbar-inner {
  display: flex;
  flex-direction: row;
  min-width: 5%;
  min-height: 120px;

} */

.ad-pmbarup div{

  background-color: #f5a06e;
}
.ad-pmbardwn div {

  background-color: #3feb00;
}

/* .csapp.showvotemode  {
  min-width: 55%;
} */

/* .ad-rlpmbarleft {
  display: flex;
  flex-direction: column;
  min-width: 5%;
  justify-content: space-between;
  flex-grow: 2;
}

.ad-pmbarup {
  min-width: 5%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ad-pmbardwn {
  min-width: 5%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
} */


.ad-reply-1 {
  background-color: #9cbbfa;
  border-radius: 0.3rem;
}
.ad-reply-2 {
  background-color: #00e9eb;
  border-radius: 0.3rem;
}
.ad-reply-3 {
  background-color: #3feb00;
  border-radius: 0.3rem;
}

.ad-inline-icon {
  /* display: block;
  align-self: stretch; */
  height: 100%;
  width: 30px !important;
}

.ad-devinfo {
  background-color: #636a7a;
  border-radius: 0.3em;
  padding: 0.2em;
  margin: 0.2em;
}



.ad-scorecheck {
  padding: 0.4em;
  border-color: #737373;
  border-style: solid;
  border-radius: 0.3em;
  background-color:inherit;
  /* width: 5%; */
}


/* .ad-smas-top.ad-smas-updated {
  animation: redToNone 4s;
}

@keyframes redToNone {
  0% { background-color:#efec2b }
  100% { background-color:#34787c}
} */


.ad-smas-ok.ad-smas-okts {
  animation: anok 5s;
}
@keyframes anok {
  0% { background-color:#69ef2b }
  100% { background-color:#486d54}
}
.ad-smas-err.ad-smas-errts {
  animation: anerr 2s;
}
@keyframes anerr {
  0% { background-color:#ef692b }
  100% { background-color:#8d8458}
}


.ad-smas-top {
  background-color:#34787c;
}

.ad-smas-ok {
  background-color: #486d54;
}
.ad-smas-err {
  background-color: #8d8458;
}


.ad-sim-adminacc {
  background-color:#34787c;
}



.ad-icon-base {
  /* display: flex; */
  /* border-radius: 0.3em; */
}
/* .ad-icon-inner {
  
  width: 70%;
  height: 70%;
  
  border-radius: 0.3em;
} */
.ad-answer-owner {
  background-color: #dea624;
}


.ad-answer-ok {
  background-color:green;
}
.ad-answer-ok.ad-answer-okts {
  animation: ad-icon-ok 5s;
}
@keyframes ad-icon-ok {
  0% { background-color:#17ce17}
  3% { background-color:#686868}
  5% { background-color:#17ce17}
  100% { background-color:green}
}

.ad-answer-err {
  background-color:red;
}
.ad-answer-err.ad-answer-errts {
  animation: ad-icon-err 5s;
}
@keyframes ad-icon-err {
  0% { background-color:#686868}
  100% { background-color: red}

  0% { background-color:#fa4848}
  3% { background-color:#686868}
  5% { background-color:#fa4848}
  100% { background-color:red}
}





.ad-csiappinfo {
  /* margin-top: 1em;
  margin-bottom: 0.2em; */
  background-color: #6c7996;
  /* border-radius: 0.3em 0.3em 0 0;
  padding: 0.3em; */
  
}
.ad-csiepoch {
  background-color: #6c7996;
  padding-bottom: 0.3em;
}
.ad-epoch-left {
  background-color: #4361a7;
  border-radius: 0.3em 0 0 0;
}
.ad-epoch-right {
  background-color: #4361a7;
  border-radius: 0 0.3em 0 0;
}
.ad-epoch-mid {
  background-color: #4361a7;
}
.ad-epoch-bot {
  background-color: #4361a7;
}
.ad-csiepoch-round {
  background-color: #6c7996;
  /* border-radius: 0.3em 0 0 0; */
  border-radius: 0.3em;
  
}

.ad-appbar {
  
  background-color: #4f5e80 !important;
  
}

.ad-csiapptop {
  border-radius: 0.3em 0.3em 0 0;
}


.ad-appbar-add svg {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
}

.adsim-baseitm {
  background-color: #709661;
  border-radius: 0.3em 0 0 0;
}


.bbar {
  display: flex;
  flex-direction: row;
  justify-content:  space-around;
  margin-bottom: 0.5em;

}

.account{
  background-color: #6646c5;
  padding:0.5em;
  //width: 80%;
  margin-bottom: 0.5em;
}

.caccbuts {
  background-color: #636a7a;
  border-radius: 0.3em;
  padding: 0.3em;
  margin-bottom: 0.3em;
}

.cacchorz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.csatokenbal {
  background-color: #737373;
  padding: 0.2em;
  width: 25%;
  border-radius: 0.3em;
}
.csabar {
  display: flex;
  flex-direction: row;
  justify-content:  space-around;
  align-items: center;
}
.barleft {
  display: flex;
  flex-direction: row;
  justify-content:  space-around;
}
.barright {
  display: flex;
  flex-direction: row;
  justify-content:  space-around;
  align-items: stretch;
}
.barbutton {
  display: flex;
  flex-direction: row;
}
.barbutton button {
  margin: 0 0.4em 0 0.4em;
 
}

.csagauge {
  width: 20%;
}

.gauge-row {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.gauge-wrapper {
  padding: 0.2em;
  padding-right: 0.6em;
  width: 100%;
  background: #eee;
  border-radius: 0.2em;
}
.gauge {
  
  background: #90A4AE;
  padding: 0.2em;
}
.csapoints {
  background-color: #737373;
  padding: 0.2em;

  border-radius: 0.3em;
}
.csapoints div {
  margin: 0 0.2em 0 0.2em;
}




.bbmain {
  display: flex;
  flex-direction: column;
  justify-content:  space-around;
  margin-bottom: 0.5em;
  background-color: #2c6549;
  padding: 0.2em;
  margin: 0.2em;
  border-radius: 0.3em;
  font-size: 50%;
}

.bbadr {
  font-size:  0.7em;
  text-overflow: ellipsis;
  width: 15%;
}

.cshorz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cshorz div {
  padding: 0.2em;
}

.cssmalladr {
  font-size: 80%;
  justify-content: flex-end;
}

.csaleft {
  display: flex;
  flex-direction: row;
}
.csaright {
  display: flex;
  flex-direction: column;
}



li.stackitm {
  background-color: #3d5d9d;
  margin:0.5em;
  padding:0.5em;
  list-style-type: none;
  border-width: medium;
  border-style: dashed;
  border-color: #022364;
}





.showlev1 .lev2 {
  display: none;
}







.ctitle {
  display: flex;
  flex-direction: row;
  justify-content:  space-around;
  margin-bottom: 0.5em;
}

.cadr {
  flex-grow: 1;
  padding:0.4em;
  background-color: #737373;
  border-radius: 0.3em;
}
.cques {
  padding:0.4em;
  margin-right: 0.2em;
  margin-left: 0.2em;
  flex-grow: 5;
  background-color: #737373;
  border-radius: 0.3em;
}
.ceditb  {
  padding:0.2em;
  background-color: #737373;
  border-radius: 0.3em;
}
.ceditb button {
  padding:0.4em;
  border-radius: 0.3em;
}


.scorecheck {
  padding: 0.4em;
  border-color: #737373;
  border-style: solid;
  border-radius: 0.3em;
  width: 5%;
}

.scorewrong {
  background-color: red;
}
.scoreok {
  background-color: green;
}

.baselist .scorecheck {
  display: none;
}




.devinfo {
  background-color: #636a7a;
  border-radius: 0.3em;
  padding: 0.2em;
  margin: 0.2em;
}

.dinfotop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.cfront {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

}

.creplies {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 50%;
}
.baserepleft {
  display: flex;
  flex-direction: column;
  min-width: 70%;
}
.nftrepleft {
  display: flex;
  flex-direction: column;
  min-width: 50%;
}
.repleft {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.repright {
  display: flex;
  flex-direction: column;
  
}

.reprightusebase {
  display: flex;
  flex-direction: row;
  justify-content:  space-around;
}

.rlpmbar {
  display: flex;
  flex-direction: row;
  min-width: 5%;
  min-height: 120px;
  border-style: solid;
  border-color: #737373;
  background-color: #3564c4;
  border-radius: 0.3em;
}

.csapp.showvotemode .rlpmbar {
  min-width: 55%;
}

.rlpmbarleft {
  display: flex;
  flex-direction: column;
  min-width: 5%;
  justify-content: space-between;
  flex-grow: 2;
}


.pmbarup {
  min-width: 5%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.pmbardwn {
  min-width: 5%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pmbarup div {
  background-color: #f5a06e;
}
.pmbardwn div {
  background-color: #3feb00;
}


.rlplusmin {

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  flex-grow: 8;
}

.rlanswers {
  display: flex;
  flex-direction: column;
}

.rlanswers div:nth-child(1) div:first-child {
  background-color: #9cbbfa;
}
.rlanswers div:nth-child(2) div:first-child {
  background-color: #00e9eb;
}
.rlanswers div:nth-child(3) div:first-child {
  background-color: #3feb00;
}


.rlq {
  margin: 0.1em;
  border-radius: 0.3em;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  min-width: 80%;
}

.rlq button{
  padding:0.3em;
}

.rlw {
  min-width: 80%;
  border-radius: 0.3em;
  padding:0.2em;
}


.csapp:not(.showvotemode) .forvote {
  display: none;
}


.csapp.showvotemode .creplies {
  display: none;
}

.cstats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.csec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cscol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cscol div div{
  margin: 0.1em;
  border-radius: 0.3em;
  padding:0.1em;
}

.cscol div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}




.pmt {
  margin: 0.1em;
  border-radius: 0.3em;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.pmt div {
  margin-left: 0.2em;
}
.pmt button {
  background-color: #f5a06e;
  padding:  0.5em 1.8em 0.5em 1.8em;
  border-radius: 0.3em;
}

.pmb button {
  background-color: #6ef571;
}

.pmm div {
  margin-right: 0.2em;
}

.pmm {
  margin: 0.1em;
  border-radius: 0.3em;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}





.rlclaim {
  margin: 0.1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rlclaim div {
  margin-right: 0.4em;
}

.inforight div div{
  margin: 0.1em;
  border-radius: 0.3em;
  padding:0.1em;
}

.inforight div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.devinfo .infoleft div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.devinfo .infoleft div div{
  margin: 0.1em;
  border-radius: 0.3em;
  padding:0.1em;
}

.caccount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 70%;

}
.caccount div {
  margin: 0.1em;
  border-radius: 0.3em;
  padding:0.1em;
  display: flex;
  flex-direction: column;
}

.repbut {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.repbut button {
  padding: 0.4em;
  margin: 0 0.2em 0 0.2em;
}

.csimain {
  //width: 80%;
  display: flex;
  flex-direction: column;
}

.csisec {
  display: flex;
  flex-direction: column;
  justify-content: space-around;


}

.csiadmin {
  background-color: #636a7a;
  border-radius: 0.3em;
  padding: 0.3em;
}

.csiadr {
  margin-top: 0.5em;
}
.csiappinfo {
  margin-top: 1em;
  margin-bottom: 0.2em;
  background-color: #4f5e80;
  border-radius: 0.3em 0.3em 0 0;
  padding: 0.3em;
}



.csicol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

}

.csihorz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.csicol div {
  margin-right: 0.5em;
}

.csibuts button {
  margin-left: 0.3em;
}








.navmain {
  display: flex;
  flex-direction: row;
  justify-content: space-around;


  


}

.navsection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex-grow: 5;
  padding: 0.3em 0.3em 0.6em 0.3em;

}

.navsection.navsml {
  flex-grow: 3;
}

.navbase {
  background-color: #4c9d68;
}
.navstack {
  background-color: #4d70b3;
}
.navmynfts {
  background-color: #3f65b2;
}

/*
.cstack ul {
  display: block;
  margin: 0;
  padding: 0;
  padding-top: 0.5em;
  overflow: scroll;
}

.cstack.baselist{
  background-color: #4c9d68;
}
.cstack.baselist li {
  background-color: #12943d;
}


.cstack.stacklist {
  background-color: #4d70b3;
}
.cstack.stacklist li {
  background-color: #3d5d9d;
}
.cstack.stacklist li.nftisbase {
  background-color: #12943d;
}


.cstack.nftlist {
  background-color: #3f65b2;
}
.cstack.nftlist li {
  background-color: #3d5d9d;
  border-color: #022364;
}
.cstack.nftlist li.nftisbase {
  background-color: #12943d;
}


.cstack ul .stackitm {

  margin-top: 0;
}
*/

